// src/components/Header.js
import React from 'react';
import logo from '../../../../assets/logo_transparent.png'; // Pfad zu Ihrem Logo

const Header = () => {
    return (
        <header className="bg-gray-800 text-white p-4">
            <div className="container mx-auto flex justify-between items-center">
                <div className="flex items-center">
                    <img src={logo} alt="Logo" className="h-8 w-8 mr-2" /> {/* Beispiel für ein Logo */}
                    <h1 className="text-2xl font-bold">GroovyStars</h1>
                </div>
                <nav>
                    <ul className="flex space-x-4">
                        <li>
                            <a href="#" className="hover:text-gray-300">
                                Home
                            </a>
                        </li>
                        <li>
                            <a href="#" className="hover:text-gray-300">
                                Team
                            </a>
                        </li>
                        <li>
                            <a href="#" className="hover:text-gray-300">
                                Writeups
                            </a>
                        </li>
                        <li>
                            <a href="#" className="hover:text-gray-300">
                                About GroovyStars
                            </a>
                        </li>
                    </ul>
                </nav>
            </div>
        </header>
    );
};

export default Header;
