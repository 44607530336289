// src/components/Home.tsx
import React, {useEffect, useState} from 'react';
import axios from "axios";

const Home: React.FC = () => {

    const [quote, setQuote] = useState<String>("");
    const api_url = "https://wheeler-walker-jr-api.vercel.app/statements?category=RANDOM";

    useEffect(() => {
        let config = {
            method: 'get',
            maxBodyLength: Infinity,
            url: api_url,
            headers: { }
        };

        axios.request(config)
            .then((response) => {
                //console.log(JSON.stringify(response.data[Math.floor(Math.random() * response.data.length)].statement));
                setQuote(JSON.stringify(response.data[Math.floor(Math.random() * response.data.length)].statement))
            })
            .catch((error) => {
                console.log(error);
            });
    }, [])

    return (
        <div className="bg-cover bg-center bg-opacity-80 h-screen flex flex-col items-center justify-center text-white"
             style={{ backgroundImage: "url('https://images.unsplash.com/photo-1464983953574-0892a716854b?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D')" }}>
            <h1 className="text-6xl font-bold mb-4">Welcome to GroovyStars CTF</h1>
            <blockquote className="text-2xl text-center italic">
                {quote}
            </blockquote>
        </div>
    );
};

export default Home;
