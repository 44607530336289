import React, {useEffect} from 'react';
import './App.css';
import {Route, Routes} from "react-router-dom";
import Layout from "./common/views/layout/Layout";
import Home from "./common/views/home/Home";

function App() {

    useEffect(() => {
        document.title = 'GroovyStars'
    }, []);

    return (
        <Routes>
            <Route path={"/"} element={<Layout/>}>
                <Route path={"/"} element={<Home/>}></Route>
            </Route>
        </Routes>

    );
}

export default App;
